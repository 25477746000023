import { Prompt } from 'react-router-dom';
import { Location, Action } from 'history';
import Modali from 'components/Modal';

interface NavigationPromptProps {
  isBlocking: boolean;
  handleBlockedNavigation: (location: Location<unknown>, action: Action) => string | boolean;
  isConfirmOpen: boolean;
  confirmModalProps: object;
}

const NavigationPrompt: React.FC<NavigationPromptProps> = ({
  isBlocking,
  handleBlockedNavigation,
  isConfirmOpen,
  confirmModalProps,
}) => (
  <>
    <Prompt when={isBlocking} message={handleBlockedNavigation} />
    {isConfirmOpen && <Modali.Modal {...confirmModalProps}></Modali.Modal>}
  </>
);

export default NavigationPrompt;
