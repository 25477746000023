// 取得網址參數
export const getParams = (key: string): string | null => {
  return new URLSearchParams(window.location.search).get(key);
};

export function validateAndFormatAddress(city: string, area: string, address: string): string {
  if (address.includes(city)) {
    address = address.replace(city, '').trim();
  }

  if (!address.includes(area)) {
    address = area + address;
  }

  if (!address.includes(city)) {
    address = city + address;
  }

  return address;
}

export function extractAddress(address: string): string {
  const extractChars = address.slice(-15);

  const afterQu = extractChars.split('區').pop() || '';

  const afterLi = afterQu.split('里').pop() || '';

  const afterLin = afterLi.split('鄰').pop() || '';

  const beforeHao = afterLin.split('號')[0] + '號';

  return beforeHao === '號' ? '' : beforeHao;
}

// 切割地址前的 "台灣" ( 顯示長短問題 )
export function cutAddress(fullAddress) {
  if (!fullAddress) return '';
  const taiwanReg = /(^\u53f0\u7063)|(^\u81fa\u7063)/;
  const fullAddressBuf = fullAddress.replace(taiwanReg, '');

  let mainAddress = '歷史紀錄';
  const reg =
    /(^[\u4E00-\u9FA5]{2}\u5e02[\u4E00-\u9FA5]{1,2}\u5340)|(^[\u4E00-\u9FA5]{2}\u7e23[\u4E00-\u9FA5]{2}\u5e02)|(^[\u4E00-\u9FA5]{2}\u7e23)/;
  const reg2 = /(^[\u4E00-\u9FA5]{2}\u7e23)|(^[\u4E00-\u9FA5]{2}\u5e02)/;
  if (reg.test(fullAddressBuf)) {
    mainAddress = fullAddressBuf.replace(reg, '');
    return mainAddress;
  } else if (reg2.test(fullAddressBuf)) {
    mainAddress = fullAddressBuf.replace(reg2, '');
    return mainAddress;
  }
  return mainAddress;
}
