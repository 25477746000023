import './addCreditCardPage.scss';

import { CreditCardImage } from './component/CreditCardImage';
import { useEffect, useState } from 'react';
import { useTappay } from '../../hooks/useTappay';
import { Button } from 'components/Button/Button';
import classNames from 'classnames';
import Modali, { useModali } from 'components/Modal';
import NavigationPrompt from 'components/NavigationPrompt';
import { setToastAppear } from 'components/Toast/Toast';

import { useHistory } from 'react-router-dom';

import * as taxiTripAPI from 'api/taxiTrip';

interface CardInfoValidate {
  cardNumber: boolean | null;
  expirationDate: boolean | null;
  ccv: boolean | null;
}

interface CardLabelInputProps {
  label: string;
  displayWidth: string;
  isError: boolean;
  tappayFieldId: string;
  errorMessage: string;
}

const CardLabelInput = ({
  label,
  displayWidth,
  isError,
  tappayFieldId,
  errorMessage,
}: CardLabelInputProps) => {
  const [showInputErrorMsg, setShowInputErrorMsg] = useState(false);
  const containerClass = classNames({
    'card-detail__field': true,
    'card-detail__field--full': displayWidth === 'full',
    'card-detail__field--half': displayWidth === 'half',
  });

  useEffect(() => {
    const tappayField = document.getElementById(tappayFieldId);
    const observer = new MutationObserver(() => {
      setShowInputErrorMsg(tappayField?.classList.contains('card-detail__input--error') || false);
    });

    if (tappayField) {
      observer.observe(tappayField, { attributes: true, attributeFilter: ['class'] });
    }

    return () => observer.disconnect();
  }, [tappayFieldId]);

  return (
    <div className={containerClass}>
      <p className="field__title">{label}</p>
      <div
        id={tappayFieldId}
        className={`card-detail__input ${isError ? 'card-detail__input--error' : ''}`}
      ></div>
      {showInputErrorMsg && <p className="field__error">{errorMessage}</p>}
    </div>
  );
};

export const AddCreditCardPage = ({
  GUID,
  isBindCardSuccess,
  userBindCards,
  setShouldRequestPosition,
}) => {
  const history = useHistory();
  const { isTappayLoadedSuccess, cardSetup, onCardUpdate, getPrime } = useTappay();
  const [canPay, setCanPay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validate, setValidate] = useState<CardInfoValidate>({
    cardNumber: null,
    expirationDate: null,
    ccv: null,
  });

  // about navigation
  const [isBlocking, setIsBlocking] = useState(true);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const [confirmCalcelCallTaxi, toggleConfirmCalcelCallTaxiModal] = useModali({
    animated: true,
    title: '取消叫車',
    message: '是否要取消叫車？',
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="取消叫車"
        isTwoButton
        onClick={() => handleCancelCallTaxi()}
      />,
      <Modali.Button
        key="ModaliButton"
        label="繼續付款"
        isStyleDestructive
        isTwoButton
        onClick={() => handleCancelRouterBack()}
      />,
    ],
  });
  // This function is called when the user tries to navigate away.
  const handleBlockedNavigation = (nextLocation) => {
    if (isBlocking && nextLocation.pathname === '/') {
      setIsConfirmOpen(true);
      toggleConfirmCalcelCallTaxiModal();
      return false;
    }

    return true;
  };
  async function handleCancelCallTaxi() {
    try {
      await taxiTripAPI.CancelCallUberTaxi({ tripGuid: GUID });
      if (confirmCalcelCallTaxi.status()) toggleConfirmCalcelCallTaxiModal();
      setIsBlocking(false);
      setShouldRequestPosition(true);
      setToastAppear('取消叫車成功');
      history.replace('/');
    } catch (err) {
      setToastAppear('取消叫車失敗');
    }
  }

  function handleCancelRouterBack() {
    if (confirmCalcelCallTaxi.status()) toggleConfirmCalcelCallTaxiModal();
    setIsConfirmOpen(false);
  }

  const [alertModal, toggleAlertModal] = useModali({
    animated: true,
    message: '新增失敗，請稍後再試',
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="確定"
        isStyleDestructive
        onClick={() => toggleAlertModal()}
      />,
    ],
  });
  function updateValidation(type: string, status: boolean | null) {
    setValidate((prevRes) => ({
      ...prevRes,
      [type]: status,
    }));
  }
  function onCardUpdateCallback(update) {
    console.log('update', update);
    if (update.canGetPrime) {
      setCanPay(true);
    } else {
      setCanPay(false);
    }

    // 信用卡卡號
    if (update.status.number === 2) {
      // 錯誤
      updateValidation('cardNumber', false);
    } else if (update.status.number === 0) {
      // 正確
      updateValidation('cardNumber', true);
    } else {
      // 無
      updateValidation('cardNumber', null);
    }

    // 有效日期
    if (update.status.expiry === 2) {
      // 錯誤
      updateValidation('expirationDate', false);
    } else if (update.status.expiry === 0) {
      // 正確
      updateValidation('expirationDate', true);
    } else {
      // 無
      updateValidation('expirationDate', null);
    }

    // 檢核碼
    if (update.status.ccv === 2) {
      // 錯誤
      updateValidation('ccv', false);
    } else if (update.status.ccv === 0) {
      // 正確
      updateValidation('ccv', true);
    } else {
      // 無
      updateValidation('ccv', null);
    }
  }

  useEffect(() => {
    if (isBindCardSuccess === false) {
      toggleAlertModal();
    }
  }, [isBindCardSuccess]);

  useEffect(() => {
    if (isTappayLoadedSuccess) {
      cardSetup({
        fields: {
          number: {
            element: '#card-number',
            placeholder: '請輸入16位數卡號',
          },
          expirationDate: {
            element: '#card-expiration-date',
            placeholder: 'MM / YY',
          },
          ccv: {
            element: '#card-ccv',
            placeholder: '卡片背面3碼',
          },
        },
        styles: {
          input: {
            color: '#484848',
            'font-size': '15px',
          },
          ':focus': {
            color: '#484848',
          },
          '::placeholder': {
            color: '#999999',
          },
          '.valid': {
            color: '#15beae',
          },
          '.invalid': {
            color: '#F14F50',
          },
          '@media screen and (max-width: 400px)': {
            input: {
              color: 'orange',
            },
          },
        },
      });
      onCardUpdate(onCardUpdateCallback);
    }
  }, [isTappayLoadedSuccess]);

  function addCreditCardHandler() {
    if (!canPay || isLoading) return;
    setIsLoading(true);
    getPrime(async (prime) => {
      if (prime && prime.status !== 0) {
        setToastAppear('新增失敗，請稍後再試');
        setIsLoading(false);
        return;
      }
      if (userBindCards.length) {
        // 變更信用卡
        await handleCreditCard(taxiTripAPI.ChangeCreditCard, {
          prime: prime.card.prime,
          frontendRedirectUrl: window.location.origin + '/?guid=' + GUID + '&isAddBindCard=true',
          extraParams: { cardGuid: userBindCards[0].guid },
        });
        return;
      }
      await handleCreditCard(taxiTripAPI.BindCreditCard, {
        prime: prime.card.prime,
        frontendRedirectUrl: window.location.origin + '/?guid=' + GUID + '&isAddBindCard=true',
      });
    });
  }
  const handleCreditCard = async (apiFunc, params) => {
    const [result, error] = await apiFunc({
      prime: params.prime,
      frontendRedirectUrl: params.frontendRedirectUrl,
      ...params.extraParams,
    });

    if (result) {
      window.location.replace(result.data.data.paymentUrl);
    }

    if (error) {
      setToastAppear('綁定失敗，請稍後再試');
      setIsLoading(false);
    }
  };

  return (
    <div className="add-credit-card">
      <div className="add-credit-card__title">
        <p className="add-credit-card__title__main">新增信用卡或簽帳金融卡</p>
        <p className="add-credit-card__title__sub">
          目前僅台新銀行、中國信託的一般信用卡交易支援銀聯卡
        </p>
      </div>
      <div className="add-credit-card__card-type">
        <CreditCardImage height="32px" type="VISA" alt="VISA" />
        <CreditCardImage height="32px" type="Mastercard" alt="Mastercard" />
        <CreditCardImage height="32px" type="JCB" alt="JCB" />
        <CreditCardImage height="32px" type="Amex" alt="Amex" />
        <CreditCardImage height="32px" type="UnionPay" alt="UnionPay" />
      </div>
      <div className="add-credit-card__card-detail">
        <CardLabelInput
          label="信用卡卡號"
          displayWidth="full"
          isError={validate.cardNumber === false}
          tappayFieldId="card-number"
          errorMessage="請輸入16位數卡號"
        />
        <CardLabelInput
          label="有效日期"
          displayWidth="half"
          isError={validate.expirationDate === false}
          tappayFieldId="card-expiration-date"
          errorMessage="請輸入有效日期"
        />
        <CardLabelInput
          label="檢核碼"
          displayWidth="half"
          isError={validate.ccv === false}
          tappayFieldId="card-ccv"
          errorMessage="請輸入CVC檢核碼"
        />
      </div>
      <div className="add-credit-card__info">
        <ul>
          <li>限綁定台灣發行之信用卡。</li>
          <li>限綁定本人信用卡。</li>
          <li>支援部分銀行的簽帳金融卡(Debit Card)，可洽發卡銀行確認是否支援綁定。</li>
          <li>
            提供綁定之銀行信用卡：國泰世華銀行、玉山銀行、台新銀行、中國信託銀行、永豐銀行、凱基銀行、合作金庫銀行、第一銀行、財團法人聯合信用卡處理中心、藍新金流、台北富邦銀行、陽信銀行、中國銀行、聯邦銀行、彰化銀行、環匯亞太。
          </li>
        </ul>
      </div>
      <div className="add-credit-card__footer">
        <p className="add-credit-card__footer__tips">系統將試刷1元測試交易，並立即退款</p>
        <Button
          width="full"
          color="primary"
          disabled={!canPay || isLoading}
          onButtonClick={addCreditCardHandler}
          isLoading={isLoading}
        >
          <span>新增卡片</span>
        </Button>
      </div>
      <Modali.Modal {...alertModal}></Modali.Modal>
      <NavigationPrompt
        isBlocking={isBlocking}
        handleBlockedNavigation={handleBlockedNavigation}
        isConfirmOpen={isConfirmOpen}
        confirmModalProps={confirmCalcelCallTaxi}
      />
    </div>
  );
};
