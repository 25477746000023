import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGoogleAdBanner } from '../../components/googleAdBanner';

export default function CarPage(props) {
  const history = useHistory();
  const { GoogleAdBanner } = useGoogleAdBanner();

  const [getOnCar, setGetOnCar] = useState(false);

  useEffect(() => {
    if (props.orderFinish) {
      setGetOnCar(true);
    }
  }, [props.orderFinish]);

  useEffect(() => {
    // 歷史紀錄假資料例外狀況處理 ( 2 周目事件 )
    if (getOnCar) {
      props.setHistoryResult((oldArray) => {
        if (
          oldArray.length !== 0 &&
          oldArray[0].isCancel &&
          oldArray[0].cancelSource === 'fake-1'
        ) {
          const newFake = { ...oldArray[0], status: 1, isCancel: false, isDispatch: true };
          oldArray.shift();
          return [newFake, ...oldArray];
        } else {
          return oldArray;
        }
      });
    }
    // 蝦米功能
    const t = setTimeout(() => {
      history.push('/');
    }, 1800000);

    return () => clearTimeout(t);
  }, [getOnCar]);

  // * 車隊名稱 - props.driverDispatch.fleet
  // * 車牌號碼 - props.driverDispatch.license_plate
  // * 抵達時間 - props.driverDispatch.minute
  // * 司機電話 - props.driverDispatch.phone

  const driverDispatchMinute =
    props.driverDispatch.minute &&
    (props.driverDispatch.minute + '').split('').map((word, i) => (
      <p key={i} className="sp-word">
        {word}
      </p>
    ));
  const contactDriver = () =>
    (location.href = `tel:${
      props.driverDispatch && props.driverDispatch.phone ? props.driverDispatch.phone : '0987878787'
    }`);

  return (
    <div className="statusPage">
      <div className="statusPage-header">
        <span>乘車資訊</span>
      </div>
      <div className="statusPage-body">
        <div className="statusPage-img margin-top-0 rwd-scale">
          <div className="statusPage-asset statusPage-info" />
          <div className="statusPage-asset statusPage-marker-line1" />
          <div className="statusPage-asset statusPage-marker-line2" />
          <div className="statusPage-asset statusPage-marker-line3" />
          <div className="statusPage-asset statusPage-marker" />
        </div>
        <div className="statusPage-list">
          <div>
            <span>車隊名稱</span>
            <span>{props.driverDispatch.fleet}</span>
          </div>
          <div>
            <span>車牌號碼</span>
            <span>{props.driverDispatch.license_plate}</span>
          </div>
          <div>
            <span>預計抵達時間</span>
            <span>{driverDispatchMinute} 分鐘</span>
          </div>
        </div>
        <div className="statusPage-cancel-warning">
          <span>
            若於3分鐘後取消叫車，將酌收<span className="primary">取消費用$40</span>。
          </span>
        </div>
      </div>
      <div className="statusPage-footer two-btn">
        <button className="btn-default" onClick={contactDriver}>
          聯絡車隊
        </button>
        <button className="btn-cancel" onClick={() => window.history.back()}>
          取消叫車
        </button>
      </div>
      {GoogleAdBanner}
    </div>
  );
}
