import { useEffect, useState } from 'react';

import { CreditCard } from './component/CreditCardSelect';
import { Button } from 'components/Button/Button';
import Modali, { useModali } from 'components/Modal';
import { setToastAppear } from 'components/Toast/Toast';

import { CreditCardType } from 'types/creditCard';
import { isNullOrUnDef, isArray } from 'utils/is';

import './selectCreditCardPage.scss';

import * as taxiTripAPI from 'api/taxiTrip';
import { EstimatedFareItem, UberTripsInfo, UserBindCardInfo } from 'api/taxiTrip/model/FEModel';

import { useHistory } from 'react-router-dom';
import { useFetchDiscount } from 'hooks/useFetchDiscount';

interface SelectCreditCardPageProps {
  GUID: string;
  isBindCardSuccess: boolean;
  tokenAlready: boolean;
  setIsBindCardSuccess: Function;
  paymentOriginPage: string;
  setShouldRequestPosition: Function;
  setOrderPlaceDetail: Function;
}

interface CreditCardShowProps {
  type: CreditCardType;
  alt: string;
  id: string | undefined;
  radioButtonText?: string;
  selected: boolean;
  bank: string;
  encryptedCardNumber: string;
}

export type CreditCardInfo = CreditCardShowProps & UserBindCardInfo;

export const SelectCreditCardPage = ({
  GUID,
  isBindCardSuccess,
  tokenAlready,
  setIsBindCardSuccess,
  paymentOriginPage,
  setShouldRequestPosition,
  setOrderPlaceDetail,
}: SelectCreditCardPageProps) => {
  const history = useHistory();
  const payAfterTaxiFinished =
    paymentOriginPage === 'history' ||
    paymentOriginPage === 'comment' ||
    paymentOriginPage === 'payUnpaidTrip';
  // about navigation
  const [isBlocking, setIsBlocking] = useState(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [compareEstimatedFareRes, setCompareEstimatedFareRes] = useState<boolean | string | null>(
    null
  );
  const [originTripInfo, setOriginTripInfo] = useState<UberTripsInfo>({});
  const [changedEstimateFare, setChangedEstimateFare] = useState<EstimatedFareItem>({
    productId: '',
    displayName: '',
    fareId: '',
    fare: 0,
    expiresUtc: '',
    cancelThresholdSecs: 0,
    minCalcelFee: 0,
  });
  const [creditCardList, setCreditCardList] = useState<CreditCardInfo[]>([]);

  const [confirmFareChangedInfo, setConfirmFareChangedInfo] = useState({
    originFare: 0,
    changedFare: 0,
    options: { discountShowText: '' },
  });
  const [confirmFareChangedModal, toggleConfirmFareChangedModal] = useModali({
    animated: true,
    title: '車資異動',
    overlayClose: false,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="取消叫車"
        isTwoButton
        onClick={() => handleCancelCallTaxi()}
      />,
      <Modali.Button
        key="ModaliButton"
        label="確認叫車"
        isStyleDestructive
        isTwoButton
        onClick={() => handleCallTaxiPay()}
      />,
    ],
  });
  const [estimatedFareErrorMessage, setEstimatedFareErrorMessage] = useState<string>('');
  const [estimatedFareErrorModal, toggleEstimatedFareErrorModal] = useModali({
    animated: true,
    overlayClose: false,
    title: '連線異常',
    message: estimatedFareErrorMessage,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="確認"
        isTwoButton
        isStyleDestructive
        onClick={() => toggleEstimatedFareErrorModal()}
      />,
    ],
  });
  const [selectedCard, setSelectedCard] = useState<CreditCardInfo>({});

  const [confirmCalcelCallTaxi, toggleConfirmCalcelCallTaxiModal] = useModali({
    animated: true,
    title: '取消叫車',
    message: '是否要取消叫車？',
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="取消叫車"
        isTwoButton
        onClick={() => handleCancelCallTaxi()}
      />,
      <Modali.Button
        key="ModaliButton"
        label="繼續付款"
        isStyleDestructive
        isTwoButton
        onClick={() => handleCancelRouterBack()}
      />,
    ],
  });

  const { getDiscountedOrderAmount } = useFetchDiscount();

  /**
   * Formats the card type based on the given cardType.
   *
   * @param {string} cardType - The card type to be formatted.
   * @returns {string} The formatted card type.
   *
   * @see {@link https://docs.tappaysdk.com/tutorial/zh/advanced.html#bind-card-api}
   */
  const cardTypeMap = {
    VISA: 'VISA',
    MasterCard: 'Mastercard',
    JCB: 'JCB',
    AMEX: 'Amex',
    'Union Pay': 'UnionPay',
  };

  function formatCardType(cardType) {
    return cardTypeMap[cardType] || '';
  }

  function setFormatCreditCard(card) {
    const formatedCreditCard = {
      ...card,
      id: card.guid,
      type: formatCardType(card.cardAssociation),
      alt: card.cardAssociation,
      radioButtonText: '預設',
      selected: true,
      encryptedCardNumber: `****${card.lastFour}`,
      bank: card.issueBank,
    };
    setCreditCardList([formatedCreditCard]);
    setSelectedCard(formatedCreditCard);
  }

  function isExpired(targetTime) {
    return Date.now() > new Date(targetTime).getTime();
  }

  useEffect(() => {
    if (!GUID) {
      history.replace('/');
    }
    const blockNavigation = (nextLocation, action) => {
      if (
        isBlocking &&
        nextLocation.pathname === '/' &&
        paymentOriginPage === 'home' &&
        action === 'POP' &&
        !payAfterTaxiFinished
      ) {
        if (!confirmCalcelCallTaxi.status()) toggleConfirmCalcelCallTaxiModal();
        return false;
      }
    };

    const unblock = history.block(blockNavigation);

    return () => {
      unblock();
      if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();
      if (confirmCalcelCallTaxi.status()) toggleConfirmCalcelCallTaxiModal();
    };
  }, []);

  useEffect(() => {
    if (isBindCardSuccess) {
      setToastAppear('新增成功');
      setIsBindCardSuccess(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBindCardSuccess]);

  useEffect(() => {
    if (tokenAlready) {
      taxiTripAPI
        .GetUserBindCard()
        .then(([res]) => {
          if (res) {
            setFormatCreditCard(res.data.data.cards[0]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tokenAlready]);

  useEffect(() => {
    if (compareEstimatedFareRes === 'error') {
      toggleEstimatedFareErrorModal();
    } else if (compareEstimatedFareRes === false) {
      toggleConfirmFareChangedModal();
    } else if (compareEstimatedFareRes === true) {
      handleCallTaxiPay();
    }
    setCompareEstimatedFareRes(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareEstimatedFareRes]);

  function handleChangeCreditCard() {
    history.push('/AddCreditCard');
  }

  function handleSelectCreditCard(card) {
    console.log(card);
    setSelectedCard(card);
  }

  async function handleCancelCallTaxi() {
    try {
      await taxiTripAPI.CancelCallUberTaxi({ tripGuid: GUID });
      setIsBlocking(false);
      setShouldRequestPosition(true);
      setToastAppear('取消叫車成功');
      setOrderPlaceDetail();
      history.replace('/');
    } catch (error) {
      setToastAppear('取消叫車失敗');
    }
  }

  function getConfirmEstimatedFare(changedEstimateFare, callTaxiData) {
    // 如果有重取車資，則拿changedEstimateFare叫車，若無則直接取callTaxiData時的車資
    if (changedEstimateFare.fareId) {
      return changedEstimateFare;
    }

    const {
      uberProductId,
      uberProductName,
      uberFareId,
      oriUberFare,
      uberFareExpiresUtc,
      uberCancelThresholdSecs,
      uberMinCancelFee,
    } = callTaxiData;

    return {
      productId: uberProductId,
      displayName: uberProductName,
      fareId: uberFareId,
      fare: oriUberFare,
      expiresUtc: uberFareExpiresUtc,
      cancelThresholdSecs: uberCancelThresholdSecs,
      minCalcelFee: uberMinCancelFee,
    };
  }

  function handleCancelRouterBack() {
    if (confirmCalcelCallTaxi.status()) toggleConfirmCalcelCallTaxiModal();
  }

  async function handleCallTaxiPay(callTaxiData = originTripInfo) {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);

    if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();

    const paymentData = {
      tripGuid: GUID,
      cardGuid: selectedCard.guid,
      redirectUrl:
        window.location.origin +
        '/?guid=' +
        GUID +
        '&isCreditCardPay=true&paymentOriginPage=' +
        paymentOriginPage,
    };

    const [result, error] = payAfterTaxiFinished
      ? await taxiTripAPI.CreditCardPay(paymentData)
      : await taxiTripAPI.CallCarAfterCreditCardPay({
          ...paymentData,
          ...getConfirmEstimatedFare(changedEstimateFare, callTaxiData),
        });

    try {
      if (error) throw error;
      if (result) {
        handleCallTaxiResult(result);
      }
    } catch (error) {
      handlePaymentError(error);
    } finally {
      setIsRequestInProgress(false);
    }
  }

  function handleCallTaxiResult(result) {
    const { paymentUrl, paymentInfo } = result?.data?.data || {};
    if (paymentUrl) {
      window.location.replace(result.data.data.paymentUrl);
      return;
    }
    if (paymentInfo) {
      updateConfirmFareChangedInfo(paymentInfo);
      toggleConfirmFareChangedModal();
    }
  }

  /**
   * 格式化折扣文案
   *
   * @param {Array<Object>} discountList - 折扣列表
   * @param {string} discountList[].key - 折扣類型的鍵
   * @param {string} discountList[].prefix - 折扣類型的前綴
   * @param {number} discountList[].value - 折扣金額
   * @throws {Error} 如果 discountList 不是陣列，則拋出錯誤
   * @returns {string} 格式化後的折扣文案。如果沒有有效折扣，則返回空字符串
   */
  function formatDiscountText(discountList) {
    if (!isArray(discountList)) {
      throw new Error('discountList 必須是一個陣列');
    }

    const validatedDiscounts = discountList.map(({ key, prefix, value }) => ({
      key,
      prefix,
      value: Math.max(0, value),
    }));

    const discountShowText = validatedDiscounts
      .reduce((acc, { prefix, value }) => {
        return value > 0 ? [...acc, `${prefix}${value}`] : acc;
      }, [])
      .join('、');

    return discountShowText ? `(已使用${discountShowText})` : '';
  }

  function updateConfirmFareChangedInfo(paymentInfo) {
    // 檢查 originTripInfo.uberFare 是否存在
    if (isNullOrUnDef(originTripInfo?.uberFare)) {
      throw new Error('originTripInfo.uberFare 未定義或為 null');
    }

    const { payAmount = 0, discountAmount = 0, bonusUsed = 0 } = paymentInfo;

    const discountList = [
      { key: 'discountAmount', prefix: '優惠折抵$', value: discountAmount },
      { key: 'bonusUsed', prefix: '紅利$', value: bonusUsed },
    ];

    setConfirmFareChangedInfo((info) => ({
      ...info,
      originFare: originTripInfo.uberFare,
      changedFare: payAmount,
      options: {
        discountShowText: formatDiscountText(discountList),
      },
    }));
  }

  function handlePaymentError(error) {
    if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();
    setToastAppear(error?.response?.data?.ErrorMessage?.Description || '發生錯誤，請稍後再試');
  }

  async function handleGoPay() {
    if (payAfterTaxiFinished) {
      await handleCallTaxiPay();
    } else {
      await checkEstimatedFareIsChanged();
    }
  }

  async function checkEstimatedFareIsChangedResult({
    changedEstimateFare,
    originDiscountedFare,
    bonusInfo,
  }) {
    const { fare, fareId } = changedEstimateFare;
    const { discountCode, ticketInstanceId, useBonus } = bonusInfo;
    const changedDiscountedFare = await getDiscountedOrderAmount({
      fare,
      fareId,
      discountCode,
      ticketInstanceId,
      useBonus,
    });
    setCompareEstimatedFareRes(changedDiscountedFare === originDiscountedFare);
    setConfirmFareChangedInfo((info) => {
      return {
        ...info,
        originFare: originDiscountedFare,
        changedFare: changedDiscountedFare,
      };
    });
  }

  async function checkEstimatedFareIsChanged() {
    if (isLoading) return;
    setIsLoading(true);
    const [resultEstimateOrigin] = await taxiTripAPI.GetRegisteredUberTaxiTrip({ tripGuid: GUID });
    let callTaxiData: UberTripsInfo;
    if (resultEstimateOrigin) {
      callTaxiData = resultEstimateOrigin.data.data.trip;
      setOriginTripInfo(callTaxiData);
      if (!isExpired(callTaxiData.uberFareExpiresUtc)) {
        handleCallTaxiPay(callTaxiData);
        return;
      }
    } else {
      setEstimatedFareErrorMessage('服務目前異常，請稍後再試');
      setCompareEstimatedFareRes('error');
      setIsLoading(false);
      return;
    }
    const { pickupLat, pickupLon, pickupAddress, dropoffLat, dropoffLon, dropoffAddress, options } =
      callTaxiData;
    function checkOptionExist(optionId) {
      return options.some((option) => option.service_option_id === optionId);
    }
    const postData = {
      pickupLat,
      pickupLon,
      dropoffLat,
      dropoffLon,
      pickupAddress,
      dropoffAddress,
      option: {
        baggage: checkOptionExist(1),
        wheelchair: checkOptionExist(2),
        pet: checkOptionExist(3),
      },
      checkPickupAdd: false,
      checkDropoffAdd: false,
    };
    const [result, error] = await taxiTripAPI.estimatedTripFare(postData);
    console.log(result);
    if (result) {
      const changedEstimateFare = result?.data?.data?.[0];
      const bonusInfo = resultEstimateOrigin?.data?.data?.bonusInfo || {};
      setChangedEstimateFare(changedEstimateFare);
      await checkEstimatedFareIsChangedResult({
        changedEstimateFare,
        originDiscountedFare: callTaxiData.uberFare,
        bonusInfo,
      });
    } else {
      console.log(error);
      setEstimatedFareErrorMessage(error.response.data.ErrorMessage.Description);
      setCompareEstimatedFareRes('error');
    }
    setIsLoading(false);
  }

  return (
    <div className="select-credit-card">
      <div className="select-credit-card__title">
        <p className="select-credit-card__title__main">信用卡付款</p>
        <p className="select-credit-card__title__sub">
          目前僅可綁定一張信用卡，若需更換請點擊下方「變更信用卡」按鈕
        </p>
      </div>
      <div className="select-credit-card__card-list">
        {creditCardList &&
          creditCardList.map((creditCard) => {
            return (
              <CreditCard
                key={creditCard.id}
                creditCard={creditCard}
                onClick={handleSelectCreditCard}
              />
            );
          })}
      </div>
      {creditCardList.length > 0 && (
        <div className="select-credit-card__change-card">
          <Button
            color="primary"
            variant="outlined"
            onButtonClick={handleChangeCreditCard}
            shape="round"
            width="108px"
            size="small"
            height="30px"
          >
            <span style={{ fontSize: '15px', fontWeight: 'bold' }}>變更信用卡</span>
          </Button>
        </div>
      )}
      <div className="select-credit-card__footer">
        <Button
          width="full"
          color="primary"
          onButtonClick={handleGoPay}
          disabled={isLoading || isRequestInProgress}
          isLoading={isLoading || isRequestInProgress}
        >
          <span>確認</span>
        </Button>
      </div>
      <Modali.Modal {...estimatedFareErrorModal}></Modali.Modal>
      <Modali.Modal {...confirmFareChangedModal}>
        <div className="confirm-fare-changed-modal__content">
          <p>
            車資已從
            <span className="confirm-fare-changed-modal__content__price">
              ${confirmFareChangedInfo.originFare}
            </span>
            調整為
            <span className="confirm-fare-changed-modal__content__price primary">
              ${confirmFareChangedInfo.changedFare}
            </span>
            {confirmFareChangedInfo.options.discountShowText && (
              <p>{confirmFareChangedInfo.options.discountShowText}</p>
            )}
          </p>
          <p>是否確認叫車？</p>
        </div>
      </Modali.Modal>
      <Modali.Modal {...confirmCalcelCallTaxi}></Modali.Modal>
    </div>
  );
};
