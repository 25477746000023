import './Header.scss';

function Header(props) {
  const shouldDisplayRedDot = props.hasRedDotDisplay && !props.menuBarBack;
  return (
    <div className="header">
      <div
        className={`${props.menuBarBack ? 'header__backBar' : 'header__menuBar'}`}
        onClick={props.toggleSideBar}
      >
        <div />
        {shouldDisplayRedDot && <div className="header__redDot" />}
      </div>
      {props.headerText && <div className="header__text">{props.headerText}</div>}
    </div>
  );
}

export default Header;
